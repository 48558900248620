import { Controller } from "@hotwired/stimulus"
import { Dropzone } from "dropzone"

// Connects to data-controller="dropzone"
export default class extends Controller {
  static values = {
    maxFileSize: Number,
    allowedExtensions: String
  };
  connect() {
    const maxFileSize = this.maxFileSizeValue; // データ属性から取得した値
    const allowedExtensions = this.allowedExtensionsValue.split(",");
    console.log(`Max file size from HTML: ${maxFileSize}`);
    console.log(`allowd extenstion from HTML: ${allowedExtensions}`);
    let form = document.getElementById("dropzone"); // targetにかえる
    new Dropzone(form, {
      paramName: 'attachment[file]',
      maxFilesize: maxFileSize,
      acceptedFiles: this.allowedExtensionsValue,
      dictFileTooBig: "ファイルサイズが大きすぎます。最大: {{maxFilesize}} MB です。",
      dictInvalidFileType: "このファイル形式は許可されていません。",
      dictDefaultMessage: 'ここにファイルをドラッグ＆ドロップしてください<br/>' +
                          '<p>または、クリックしてファイルを選択してください</p>' +
                          `<p>上限 : ${maxFileSize}MB<br/>( ファイルは複数選択できます )</p>`,
      init: function() {
        this.on('success', function(file, responseText) {
          // console.log(responseText);
          Turbo.renderStreamMessage(responseText);
        });
        this.on('error', function(file, errorMessage, xhr) {
          console.error("アップロードエラー:", errorMessage);
          // サーバーからのエラーレスポンスがTurbo Streamsの場合に処理
          if (xhr && xhr.responseText) {
            Turbo.renderStreamMessage(xhr.responseText);
          }
        });
      }
    });
  }
}
